import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BannerSecondary from "../../components/banners/bannerSecondary"
import Why from "../../components/why"
import Benefits from "../../components/benefits"
import Reviews from "../../components/reviews"
import Treatments from "../../components/treatments"
import CallToAction from "../../components/callToAction"
import FeaturedFaq from "../../components/faqs/featuredFaq"
import {
  Container,
  Flex,
  FlexMobileOpp,
  Section,
} from "../../components/layoutComponents"
import { StaticImage } from "gatsby-plugin-image"
import { AnchorInline } from "../../components/buttons"
import Calander from "../../components/calander"

export default function CraniosacralMassageCalgary() {
  return (
    <Layout>
      <SEO
        title="Craniosacral Massage Therapy near Calgary"
        description="We offer craniosacral massage therapy in Calgary to help you with"
      />
      <BannerSecondary
        title="Craniosacral therapy near calgary"
        description="Using a gentle touch and manipulating the skull to release tension deep in the body and relieve pain and dysfunction while improving general health."
        to1="/"
        link1="home"
        to2="/treatments"
        link2="treatments"
        to3="/treatments/craniosacral-massage-calgary"
        link3="craniosacral massage calgary"
      />
      <Section>
        <Container className="spacing">
          <h1 className="title bold caps">
            craniosacral massage therapy calgary
          </h1>
          <Flex>
            <div className="spacing">
              <p>
                Our Craniosacral massage Calgary therapy is a gentle form of
                therapy that releases tensions deep in the body to relieve pain
                and dysfunction and improve general health. Using a very gentle
                touch, our practitioners release restrictions in the soft
                tissues that surround the central nervous system.
              </p>
              <p>
                The central nervous system controls all that the body does, and
                is highly influenced by the membranes and fluid that surround,
                protect and nourish the brain and spinal cord. This system is
                known as the craniosacral system. This system can be distorted
                by other tissues in the body, which then impedes the function of
                the brain and spinal cord. As the brain and spinal cord are
                responsible for almost all bodily functions, this can impact
                overall health. Our craniosacral therapy Calgary aims to gently
                restore the balance and function of the craniosacral system by
                releasing any restriction on the tissues influencing this
                system. This normalizes the environment around the brain,
                activating the body’s own self-healing abilities.
              </p>
              <div>
                <p>
                  We are located just South of Calgary, and to many small towns
                  including:
                </p>
                <ul>
                  <li>Foothills</li>
                  <li>Diamond Valley</li>
                  <li>Millarville</li>
                  <li>Black Diamond</li>
                  <li>Turney Valley</li>
                  <li>Okotokes</li>
                  <li>Bragg Creek</li>
                  <li>Aldersyde</li>
                  <li>and more!</li>
                </ul>
              </div>
            </div>
            <StaticImage
              src="../../images/craniosacral-massage-calgary.jpg"
              alt="craniosacral massage calgary - calgary head massage expert"
              className="stretch"
            />
          </Flex>
        </Container>
      </Section>
      <Section>
        <Container className="spacing">
          <h2 className="title bold caps">craniosacral massage benefits</h2>
          <FlexMobileOpp>
            <StaticImage
              src="../../images/craniosacral-massage-benefits.jpg"
              alt="craniosacral massage therapy calgary, ab"
            />
            <div>
              <p>
                Craniosacral massage therapy shares many of th ebenfits of
                therapeutic relaxation. It can also have an impact on many of
                the following disorders and health conditions:
              </p>
              <ul>
                <li>Migraine Headaches</li>
                <li>Chronic Neck and Back Pain</li>
                <li>Motor-Coordination Impairments</li>
                <li>Colic</li>
                <li>Autism</li>
                <li>Centeral Nervous System Disorders</li>
                <li>Orthopedic Problems</li>
                <li>Concussions and Traumatic Brain Injuries</li>
                <li>Alzheimer's Disease and Dementia</li>
                <li>Spinal Cord Injuries</li>
                <li>Scoliosis</li>
                <li>Infantile Disorders</li>
                <li>Learning Disabilities</li>
                <li>Chronic Fatigue</li>
                <li>Emotional Difficulties</li>
                <li>Stress and Tension-Related Problems</li>
                <li>Fibromyalgia and other Connective-Tissue Disorders</li>
                <li>Temporomandibular Joint Syndrome (TMJ)</li>
                <li>Neurovascular or Immune Disorders</li>
                <li>Post-Traumatic Stress Disorder</li>
                <li>Post-Surgical Dysfunction</li>
              </ul>
            </div>
          </FlexMobileOpp>
          <p className="center">
            During a Craniosacral therapy session, or specialized head massage
            Calgary, you may feel peaceful and relaxed, and may fall asleep. You
            may also feel small subtle movements in your tissues and joints
            around the pelvis, spine and skull. The information on this page has
            been collected from{" "}
            <AnchorInline
              href="https://www.upledger.com/therapies/faq.php"
              target="_blank"
            >
              https://www.upledger.com/therapies/faq
            </AnchorInline>
          </p>
        </Container>
      </Section>
      <Reviews />
      <CallToAction />
      <Why />
      <Benefits />
      <FeaturedFaq />
      <Treatments />
      <CallToAction />
      <Calander />
    </Layout>
  )
}
